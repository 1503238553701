import BaseModel from 'models/base'
import Product from 'models/product'

export default class CreditUsage extends BaseModel {
  url () {
    if (this.id) {
      return `/credit_usages/${this.id}`
    } else {
      return '/credit_usages'
    }
  }

  hasCredits () {
    return this.get('subscribed') || this.get('credits_available') > 0
  }

  canRedeemCredit () {
    return this.get('subscribed') && this.hasCredits()
  }

  hasFreeCredits () {
    return !this.get('subscribed') && this.get('credits_available') > 0
  }

  // uses the "free" credits (vs. bundled or subscribed)
  free () {
    return this.get('persisted') && !this.get('subscribed')
  }

  redeemCredit () {
    return this.command(`${this.url()}/redeem_credit`, {
      success: () => {
        return this.trigger('credit_redeemed')
      },
    })
  }

  parse (resp, options) {
    super.parse(resp, options)
    if (this.product == null) {
      this.product = this.hasOne('product', Product, resp)
    }
    return resp
  }
}
