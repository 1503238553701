/* global Backbone, $, _, _E */
import { flashError } from 'helpers/flash'
import { typeset } from 'helpers/mathjax'

export default class Base extends Backbone.View {
  initialize (options) {
    let modelId
    this.options = options || {}
    _.defaults(this.options, { registerGlobal: true })
    this.views = []
    if (_E.views == null) {
      _E.views = []
    }
    if (this.options.registerGlobal && this.model?.identifier) {
      modelId = this.model.identifier()
    }
    if (modelId) {
      _E.views[modelId] = this
    }
    this.parent = this.options.parent
  }

  isRendered () {
    return $.contains(document, this.el)
  }

  tooltipOptions () {
    const whiteList = $.fn.tooltip.Constructor.Default.whiteList

    whiteList.table = ['class', 'border', 'cellpadding', 'cellspacing']
    whiteList.tbody = []
    whiteList.tr = []
    whiteList.td = ['style']

    return { whiteList }
  }

  allTooltips (element, action) {
    if (element == null) {
      element = this.$el
    }

    const options = this.tooltipOptions()
    element.find('[data-toggle="tooltip"]').each(function () {
      if ($(this).attr('data-container') == null) {
        $(this).attr('data-container', 'main')
      }

      $(this).tooltip(options)
      $(this).tooltip(action)
    })

    // also handle programmatically set tooltips
    element.find(':data(toggle)').each(function () {
      const $this = $(this)
      if ($this.data('toggle') === 'tooltip') {
        if ($this.attr('data-container') == null) {
          $this.attr('data-container', 'main')
        }
        $this.tooltip(action)
      }
    })
  }

  refreshTooltips (element) {
    this.allTooltips(element || this.$el)
  }

  destroyTooltips (element) {
    this.allTooltips(element, 'dispose')
  }

  showTooltips (element) {
    this.allTooltips(element, 'show')
  }

  hideTooltips (element) {
    this.allTooltips(element, 'hide')
  }

  aroundTooltips (element, callback = $.noop) {
    this.destroyTooltips(element)
    callback()
    this.refreshTooltips(element)
  }

  updateTooltip (element, value) {
    $(element).tooltip('dispose').attr('title', value).tooltip('enable')
  }

  assign (view, selector, options = {}) {
    _.defaults(options, { local: true, mathjax: false })
    this.assignElement(view, selector, options.local, options.mathjax)
    view.render(options)
    if (options.mathjax) {
      typeset(view.el)
    }
  }

  assignElement (view, selector, local = true, mathjax = false) {
    view.parent = this
    selector = local ? this.$(selector) : $(selector)
    view.setElement(selector)
    if (mathjax) {
      view.$el.css('visibility', 'hidden')
    }
  }

  addChild (child) {
    this.views.push(child)
    child.parent = this
    return child
  }

  removeChild (child) {
    this.views = _.without(this.views, child)
  }

  lazyFetch (obj, options = {}) {
    _.defaults(options, {
      silent: false,
      success: () => this.render(),
    })

    if (obj.get('shallow')) {
      this.fetch(obj, options)
    } else {
      options.success(false)
    }
  }

  fetch (obj, options = {}) {
    const success = options.success || this.render

    options.success = () => success(true)
    options.lazy = true

    _.delay(() => {
      obj.fetch(options)
    })
  }

  // handles hooks for view to respond to model saves.
  autosaving (statusEl, model) {
    model ||= this.model
    if (statusEl == null) {
      statusEl = '.save-indicator'
    }
    statusEl = $(statusEl)

    this.listenTo(model, 'request', () => statusEl.addClass('show'))
    this.listenTo(model, 'save', () => statusEl.removeClass('show'))
    this.listenTo(model, 'command', () => statusEl.removeClass('show'))
    this.listenTo(model, 'destroy', () => statusEl.removeClass('show'))
    this.listenTo(model, 'error', (model, error) =>
      flashError(error.responseJSON, 'An error has occurred')
    )
    statusEl.removeClass('show')
  }

  autosaveOnChange (model) {
    model ||= this.model
    this.listenTo(model, 'change', (model, options) => {
      // don't re-save if the only changed field is ID
      const keys = _.keys(model.changed)
      if (keys.length !== 1 || keys[0] !== 'id') {
        model.save(null, { autosave: true })
      }
    })
  }

  cleanup (options = {}) {
    if (options.detach == null) {
      options.detach = true
    }
    this.cleanupChildren()
    this.destroyTooltips()
    this.stopListening()
    this.undelegateEvents()
    if (this.model?.identifier) {
      delete _E.views[this.model.identifier()]
    }
    if (this.parent?.removeChild && options.detach) {
      this.parent.removeChild(this)
    }
  }

  cleanupChildren () {
    this.views.map((child) => ((child) => child?.cleanup())(child))
  }

  actsLikeButton (el) {
    el ||= this.el
    $(el)
      .find('.is-button')
      .each(function () {
        $(this).attr('tabindex', 0)
        if ($(this).attr('role') == null) {
          $(this).attr('role', 'button')
        }
        if ($(this).attr('role') === 'checkbox') {
          $(this).attr('aria-checked', false)
        }
      })
  }

  useProgressBar (model, once = false) {
    const bar = this.progressBar || _E.progressBar

    if (once) {
      bar.attachOnce(model)
    } else {
      bar.attach(model)
    }
  }

  // only use this method if you plan on implementing the necessary child methods
  changeModel (model, options = {}) {
    _.defaults(options, { render: false })

    this.cleanup({ ...options, detach: false })
    this.model = model

    if (this.initViews) {
      this.initViews()
    }

    if (this.listenToModel) {
      this.listenToModel()
    }
    this.delegateEvents()

    if (options.render) {
      this.render(options)
    }

    options.callback?.()
  }

  firstFetch (collection, callback) {
    if (!(collection.fetched || collection.fetching)) {
      collection.fetch({ routed: true, success: callback })
    } else {
      callback?.()
    }
  }

  disableInputs () {
    this.$('input, textarea').attr('readonly', '')
    this.$('input[type="radio"], input[type="checkbox"], select').attr(
      'disabled',
      'disabled'
    )
  }

  instantiateView (Fn, options) {
    if (Fn.prototype) {
      return new Fn(options)
    } else {
      return Fn(options)
    }
  }
}
