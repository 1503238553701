/* globals $ */
import BaseView from 'views/base'
import SearchAutocomplete from 'views/search/autocomplete'

export default class SearchHeader extends BaseView {
  events () {
    return {
      'focus .autocomplete-input': 'onSearchFocus',
      'blur .autocomplete-input': 'onSearchBlur',
    }
  }

  initialize (options) {
    super.initialize(options)
    this.autocomplete = new SearchAutocomplete({
      searchUrl: this.options.searchUrl,
      showClearButton: false,
      ariaLabel: 'repository',
      id: 'search-header-input',
    })
  }

  render () {
    this.assign(this.autocomplete, this.el, { local: false })

    return this
  }

  toggleSearchFocus (val) {
    this.$('.autocomplete-container').toggleClass(
      'autocomplete-container-focused',
      val
    )

    const navbar = this.$el.parent('.navbar-nav')
    navbar.toggleClass('autocomplete-navbar-focused', val)
    $('.search-root').toggleClass('autocomplete-navbar-pin-right', val)
  }

  //
  // event handlers
  //
  onSearchFocus (e) {
    this.toggleSearchFocus(true)
  }

  onSearchBlur (e) {
    this.toggleSearchFocus(false)
  }
}
