import BaseModel from 'models/base'

export default class User extends BaseModel {
  url () {
    return '/users'
  }

  isNew () {
    return false
  } // this is a singleton
}
