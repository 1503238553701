import _templates_header_search_bar_ from 'templates/header/search_bar'
/* globals $, JST */
import SearchAutocomplete from 'views/search/autocomplete'
import BaseView from 'views/base'

export default class SearchBar extends BaseView {
  events () {
    return {
      'click .close-bar': 'onCloseClicked',
      keydown: 'onKeydown',
      'blur .autocomplete-input': 'onInputBlur',
    }
  }

  initialize (options) {
    super.initialize(options)
    this.autocomplete = this.addChild(this.getAutocomplete())
  }

  render () {
    this.$el.addClass('align-items-center')
    this.$el.html(_templates_header_search_bar_())
    this.assign(this.autocomplete, '.search-bar-container')
    return this
  }

  show () {
    this.lastActive = document.activeElement
    this.$el.addClass('show')
    this.$('.autocomplete-input').focus()
  }

  hide () {
    if (this.$el.hasClass('show')) {
      this.$el.removeClass('show')
      $(this.lastActive).focus()
    }
  }

  getAutocomplete () {
    return new SearchAutocomplete({
      searchUrl: '/search.json',
      ariaLabel: 'Search bar',
      id: 'search-bar-input',
    })
  }

  setAutocomplete (autocomplete) {
    this.autocomplete?.cleanup()
    this.autocomplete = autocomplete
    this.assign(this.autocomplete, '.search-bar-container')
  }

  //
  // event handlers
  //
  onKeydown (e) {
    if (e.which === 27) {
      this.hide()
    } // escape
  }

  onCloseClicked (e) {
    this.hide()
  }

  onInputBlur (e) {
    this.hide()
  }
}
