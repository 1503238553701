export default function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="d-flex flex-column p-5">\n  <h2>It\'s coming soon...</h2>\n  <p class="mt-4">\n    No worries, you and your students will automatically see the enhanced experience when it goes live in a few weeks. If you want to opt in before that, go to Account under <a href="/users" class="blue">Account Status</a>.\n  </p>\n  <p>\n    <a\n      class="blue"\n      href="https://edfinity.zendesk.com/hc/en-us/articles/21556659741197-Welcome-to-an-enhanced-Edfinity-experience"\n      target="_blank"\n    >\n      Learn more about the new features\n    </a>\n  </p>\n\n  <div class="buttons mt-4">\n    <button\n      class="btn btn-blue"\n      type="button"\n      id="ui-close"\n      data-dismiss="modal"\n    >\n      Close\n    </button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}