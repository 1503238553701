/* global _, $ */

import DialogLocking from 'views/modules/dialog_locking'

// dynamically grow/shrink an element's height based on window height
export const matchWindowHeight = (
  element,
  offset = 0,
  allowSmaller = false
) => {
  const navbar = $('.navbar-fixed-top')
  const footer = $('.footer-calc').children().first() || $('.footer-calc')
  const attr = allowSmaller ? 'max-height' : 'height'
  offset = offset + 1
  let scrollOffset = 0

  const matchHeight = function (element) {
    let currentOffset = offset + navbar.outerHeight()
    if (footer.is(':visible')) {
      currentOffset += footer.outerHeight() - 3
    }
    $(element).css(attr, $(window).height() - currentOffset + scrollOffset)
  }

  $(element).css({ 'overflow-y': 'auto' })
  matchHeight(element)
  $(window).resize(() => matchHeight(element))
  $(window).on('scroll', function () {
    const topX = getScrollTop()
    scrollOffset = topX > navbar.outerHeight() ? navbar.outerHeight() : topX
    matchHeight(element)
  })
  $(window).on('recalc_padding', () => matchHeight(element))
}

export const matchElementHeight = function (
  element,
  matchedElement,
  offset = 0
) {
  $(element).css({ 'overflow-y': 'auto' })
  const matchHeight = (element) =>
    $(element).height($(matchedElement).height() - offset)
  matchHeight(element)

  $(window).resize(() => matchHeight(element))
  $(window).on('recalc_padding', () => matchHeight(element))
}

export const matchElementWidth = function (
  element,
  matchedElement,
  offset = 0
) {
  $(element).css({ 'overflow-x': 'auto' })
  const matchWidth = (element) =>
    $(element).width($(matchedElement).width() - offset)
  matchWidth(element)

  $(window).resize(() => matchWidth(element))
  $(window).on('recalc_padding', () => matchWidth(element))
}

// Binds child fields (input, data-toggle buttons and textareas) to a Backbone model. By default, when a field changes
// the model will be changed accordingly. Specify a callback to override this behavior.
export const bindToModel = function (element, model, callback) {
  element = $(element)
  if (!callback) {
    callback = (field, value) => model.set(field, value)
  }

  element.find('[data-toggle="buttons-radio"] button').each(function () {
    const button = $(this)
    const field = button.closest('div').data('field')
    const value = button.data('value')
    if (model.get(field) === value) {
      button.addClass('active')
    }
    button.click(function () {
      if ($(this).hasClass('active')) {
        return false
      }
      $(this).siblings().removeClass('active')
      const radio = $(this).closest('div')
      callback(radio.data('field'), $(this).data('value'))
    })
  })

  element.find('[data-toggle="buttons-checklist"] button').each(function () {
    let button = $(this)
    const field = button.data('field')
    button.data('value', model.get(field))
    if (model.get(field)) {
      button.addClass('active')
    }
    button.click(function () {
      button = $(this)
      const value = !button.data('value')
      button.data('value', value)
      callback(button.data('field'), value)
    })
  })

  element.find('input, textarea, select').each(function () {
    const $input = $(this)
    if (
      !_(['radio', 'checkbox']).include($input.attr('type')) &&
      !_(model.get($input.attr('name'))).isUndefined() &&
      $input.data('bind') !== false
    ) {
      $input.val(model.get($input.attr('name')))
    }

    if ($input.data('bind') !== false) {
      $input.change(function () {
        const val =
          $input.attr('type') === 'checkbox' && !$input.prop('checked')
            ? null
            : $input.val()
        callback($input.attr('name'), val)
      })
    }
  })
}

// enables or disables all child input and text area fields within an element
export const enableAllFields = function (
  element,
  enabled,
  selector = 'input, textarea, a:not(.force-enabled), button, select'
) {
  element = $(element)
  element.find(selector).each(function () {
    if (element.is('select')) {
      if (enabled) {
        $(this).prop('disabled', false)
      } else {
        $(this).prop('disabled', 'disabled')
      }
    } else {
      if (enabled) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disabled')
      } else {
        $(this).attr('disabled', 'disabled')
        $(this).addClass('disabled')
      }
    }
  })
}

export const enableIf = function (element, condition) {
  element = $(element)
  const setDisabled =
    element.is('input') || element.is('select') || element.is('button')

  if (condition) {
    element.removeClass('disabled')
    if (setDisabled) {
      element.removeAttr('disabled')
    }
  } else {
    element.addClass('disabled')
    if (setDisabled) {
      element.attr('disabled', 'disabled')
    }
  }
}

export const toggleSubmitButton = function (
  button,
  condition,
  classes = 'disabled wait'
) {
  if (condition) {
    button.removeClass(classes).removeAttr('disabled')
  } else {
    button.addClass(classes).attr('disabled', 'disabled')
  }
}

export const slideIf = function (element, condition) {
  element = $(element)
  if (condition) {
    element.slideDown()
  } else {
    element.slideUp()
  }
}

export const errorMessagesToHtml = function (error, defaultMessage) {
  const hasErrors = error.errors != null && !_(error.errors).isEmpty()
  const message = _.compact([
    error.message || (!hasErrors ? defaultMessage : undefined),
  ])

  if (hasErrors) {
    message.push(
      _.map(error.errors, function (value, key) {
        if (key !== 'base') {
          return _.str.capitalize(_.str.humanize(key)) + ' ' + value
        } else {
          return _.str.capitalize(value)
        }
      }).join('<br>')
    )
  } else if (error.error) {
    message.push(error.error)
  }
  return _(message).compact().join('<br>')
}

export const showIf = (element, condition) =>
  $(element).toggleClass('d-none', !condition)

export const showModal = function (modal, options) {
  const locker = _({}).extend(DialogLocking)

  modal.attr('aria-modal', true)
  modal.attr('aria-hidden', false)
  $('#root').attr('aria-hidden', true)
  modal.modal(options || 'show')

  if (options?.lockInteraction) locker.lockInteractionTo(modal, modal.get(0))

  modal.bind('hidden.bs.modal', (e) => {
    modal.attr('aria-modal', false)
    modal.attr('aria-hidden', false)
    $('#root').attr('aria-hidden', false)

    if (options?.lockInteraction) locker.unlockInteraction()
    options?.callback?.()
  })
}

export const isNonprintingKeyCode = function (k) {
  if (k === 20) {
    return true
  } // Caps lock
  if (k === 16) {
    return true
  } // Shift
  if (k === 9) {
    return true
  } // Tab
  if (k === 27) {
    return true
  } // Escape Key
  if (k === 17) {
    return true
  } // Control Key
  if (k === 91) {
    return true
  } // Windows Command Key
  if (k === 19) {
    return true
  } // Pause Break
  if (k === 18) {
    return true
  } // Alt Key
  if (k === 91) {
    return true
  } // Win Key
  if (k === 93) {
    return true
  } // Right Click Point Key
  if (k >= 35 && k <= 40) {
    return true
  } // Home, End, Arrow Keys
  if (k === 45) {
    return true
  } // Insert Key
  if (k >= 33 && k <= 34) {
    return true
  } // Page Down, Page Up
  if (k >= 112 && k <= 123) {
    return true
  } // F1 - F12
  if (k >= 144 && k <= 145) {
    return true
  } // Num Lock, Scroll Lock
  return false
}

export const hideDropdowns = function (el = 'body') {
  $(el).find("[data-toggle='dropdown']").dropdown('hide')
}

export const notActionableTarget = function (e = false) {
  const target = $(e.target)
  return !(
    e.target.tagName.toLowerCase() === 'a' ||
    target.is(':button') ||
    target.closest('a').get(0) ||
    target.closest('button').get(0) ||
    target.closest('form').get(0) ||
    target.closest('.list-dropdown').get(0) ||
    target.closest('.list-button').get(0) ||
    target.closest('.selection-toggle').get(0)
  )
}

export const getScrollTop = () => $('html, body').scrollTop()

export const isLargeDisplay = () => window.innerWidth > 991

export const isExtraLargeDisplay = () => window.innerWidth > 1199

export const isMobileDevice = () =>
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1

export const optimalViewing = () =>
  typeof document.body.style.flexWrap !== 'undefined' &&
  typeof Array.prototype.flat !== 'undefined'

export const inIFrame = () => window.location !== window.parent.location

export const popupCenter = function (url, width, height, name) {
  const left = screen.width / 2 - width / 2
  const top = screen.height / 2 - height / 2
  window.open(
    url,
    name,
    'menubar=no,toolbar=no,status=no,width=' +
      width +
      ',height=' +
      height +
      ',toolbar=no,left=' +
      left +
      ',top=' +
      top
  )
}

export const csrfToken = () =>
  // for forms that don't use Rails form helpers
  $('meta[name="csrf-token"]').attr('content')

export const listStyleLabel = (index) =>
  '(' + String.fromCharCode(index + 65).toUpperCase() + ')'

export const checkForPdfs = function () {
  const pdfViewer = $('.pdf-viewer')
  _.each(pdfViewer, function (cnt) {
    const container = $(cnt)
    const pdfObj = container.find('object[type="application/pdf"]')
    if (pdfObj && isMobileDevice()) {
      container.html(pdfObj.html())
    }
  })
}

export const ensureAltImageText = (el, defaultText) => {
  $(el).find('img:not([alt])').attr('alt', defaultText)
}
