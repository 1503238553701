/* global _, Backbone */
export default {
  hasMany (key, modelClass, resp, attrs) {
    const options = { model: modelClass, parse: true }
    _.defaults(options, attrs)

    const val = !key ? resp : resp[key]
    if (val || options.allowEmpty) {
      const collection = new Backbone.Collection(val, options)
      collection.each((model) => this.relateTo(model))
      if (key !== 'none') {
        delete resp[key]
      }

      return collection
    }
  },

  hasOne (key, ModelClass, resp, attrs) {
    if (resp[key]) {
      const options = { parse: true }
      _.defaults(options, attrs)
      const model = new ModelClass(resp[key], options)

      this.relateTo(model)
      delete resp[key]

      return model
    }
  },

  relateTo (model) {
    model[this.parentName()] = this
    if (model.afterParentSet) {
      model.afterParentSet()
    }
  },
}
