/* globals _E */
import BaseView from 'views/base'
import SearchHeader from './search_header'

export default class Header extends BaseView {
  events () {
    return {
      'click .btn-search-bar-expand': 'onExpandSearchBar',
      'focus .autocomplete-input': 'onSearchFocused',
    }
  }

  initialize (options) {
    super.initialize(options)
    this.searchHeader = this.addChild(
      new SearchHeader({ searchUrl: '/search.json' })
    )
  }

  render () {
    this.assign(this.searchHeader, '.autocomplete-root')
    this.$('#userDropdown').tooltip()
    this.refreshTooltips()
    return this
  }

  //
  // event handlers
  //
  onExpandSearchBar (e) {
    _E.searchBar.show()
  }
}
