// Takes an object with keys with dot.notation and deepens it into dot{notation:{}}
export const deepFromFlat = function (o) {
  const oo = {}
  let t
  let parts
  let part

  for (const k in o) {
    t = oo
    parts = k.split('.')
    const key = parts.pop()
    while (parts.length) {
      part = parts.shift()
      t = t[part] = t[part] || {}
    }
    t[key] = o[k]
  }

  return oo
}
