export default function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<form\n  role="search"\n  action="/search"\n  aria-label="');
    
      __out.push(__sanitize(this.options.ariaLabel));
    
      __out.push('"\n  class="autocomplete-container d-flex align-items-center"\n>\n  <input\n    type="text"\n    placeholder="');
    
      __out.push(__sanitize(this.options.placeholder));
    
      __out.push('"\n    class="autocomplete-input"\n    autocomplete="off"\n    value="');
    
      __out.push(__sanitize(this.options.query));
    
      __out.push('"\n    id="');
    
      __out.push(__sanitize(this.options.id));
    
      __out.push('"\n    aria-label="');
    
      __out.push(__sanitize(this.options.ariaLabel));
    
      __out.push(' input"\n  />\n\n  ');
    
      if (this.options.showClearButton) {
        __out.push('\n    <button\n      class="btn btn-autocomplete-clear"\n      data-toggle="tooltip"\n      title="Clear search"\n      aria-label="Clear search"\n    >\n      <span\n        class="fa fa-times gray500"\n        aria-hidden="true"\n      ></span>\n    </button>\n  ');
      }
    
      __out.push('\n</form>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}