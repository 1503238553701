import Router from 'mounts/router'
import Edfinity from 'edfinity'

document.onreadystatechange = function () {
  if (document.readyState === 'complete') {
    Router()
    Edfinity.handleDocumentReady()
  }
}

Edfinity.initialize()
