import _templates_beta_opt_out_ from 'templates/beta/opt_out'
/* global _E, _, JST, $ */
import User from 'models/user'
import { showModal } from 'helpers/view'
import BaseView from 'views/base'

export default class BetaOptIn extends BaseView {
  el () {
    return '#opt-in-modal'
  }

  events () {
    return {
      'click #ui-opt-in': 'onOptIn',
      'click #ui-opt-out': 'onOptOut',
    }
  }

  initialize (options) {
    super.initialize(options)
    this.model = new User()
    this.model.id = _E.currentUser.id
  }

  show () {
    // force user to click a button to acknowledge
    showModal($('#opt-in-modal'), {
      backdrop: 'static',
      keyboard: false,
      lockInteraction: true,
      callback: () => {
        this.model.save({ ui_opt_in_informed: true })
      },
    })
    _.defer(() => this.$('#ui-opt-in').focus())
  }

  //
  // event handlers
  //
  onOptIn () {
    this.model.save(
      { ui_opt_in_informed: true, ui_features: true },
      {
        success () {
          window.location.reload()
          $('#opt-in-modal').modal('hide')
        },
      }
    )
  }

  onOptOut () {
    this.model.save({ ui_opt_in_informed: true })
    this.$el.find('.modal-content').html(_templates_beta_opt_out_())
    this.$('#ui-close').focus()
  }
}
