/* global _ */
// eslint-disable-next-line no-undef
AirbrakeClient = require('airbrake-js')

let airbrake = {
  notify: console.error,
}

if (process.env.AIRBRAKE_PROJECT_ID) {
  // eslint-disable-next-line no-undef
  airbrake = new AirbrakeClient({
    projectId: process.env.AIRBRAKE_PROJECT_ID,
    projectKey: process.env.AIRBRAKE_API_KEY,
  })

  airbrake.addFilter((notice) => {
    var messagesToIgnore = [
      'Loading chunk 692 failed',
      'l.LegacyGlobal.should_do_lastpass_here is not a function',
      'Extension context invalidated',
      'mce-visual-caret-hidden',
      'Desmos',
      'MathJax.typesetPromise still undefined',
      'SIGHUP',
      'Sidekiq::Shutdown',
      'ChunkLoadError: Unhandled Promise Rejection',
      'Cannot redefine property: googletag',
      'js-agent.newrelic.com',
    ]

    if (
      messagesToIgnore.some(
        (item) => notice.errors[0].message.indexOf(item) > -1
      )
    )
      return null

    if (
      _.any(
        notice.errors[0].backtrace,
        (line) => line.file.indexOf('safari-extension') > -1
      )
    )
      return null
    notice.context.environment = process.env.RAILS_ENV
    return notice
  })
}

module.exports = airbrake
