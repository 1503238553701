import disabled from '@edfinity/ally/src/maintain/disabled'
import hidden from '@edfinity/ally/src/maintain/hidden'
import tabFocus from '@edfinity/ally/src/maintain/tab-focus'

export default {
  lockInteractionTo (filter, context) {
    this.disabledHandle = disabled({ filter })
    this.hiddenHandle = hidden({ filter })
    this.tabHandle = tabFocus({
      context,
      includeContext: true,
    })
  },

  unlockInteraction () {
    this.disabledHandle.disengage()
    this.tabHandle.disengage()
    this.hiddenHandle.disengage()
  },
}
