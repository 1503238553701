/* global MathJax */
import { loadMathJax } from 'init/mathjax.init'

// Used to hold chain of typesetting calls
let promise = Promise.resolve()

export async function typeset (el) {
  await loadMathJax()
  promise = promise
    .then(() => MathJax.typesetPromise([el]))
    .catch((err) => console.log('Typeset failed: ' + err.message))

  return promise
}
