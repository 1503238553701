import _templates_search_autocomplete_ from 'templates/search/autocomplete'
/* global JST, $, _ */
import BaseView from 'views/base'

export default class SearchAutocomplete extends BaseView {
  initialize (options) {
    super.initialize(options)
    this.open = false

    _.defaults(this.options, {
      suggestMinLength: 2,
      placeholder: 'Search',
      searchUrl: '/search',
      id: 'search-input',
      showClearButton: true,
      ariaLabel: 'search',
      callback (selected) {
        if (selected.url) {
          window.location = selected.url
        }
      },
    })
  }

  events () {
    return {
      'click .btn-autocomplete-clear': 'onClearSearch',
      'click .autocomplete-input': 'onSearchClicked',
    }
  }

  render () {
    this.$el.html(
      _templates_search_autocomplete_({ options: this.options })
    )

    this.$('.autocomplete-input')
      .categorysearch({
        source: (request, response) => {
          $.ajax({
            url: this.options.searchUrl,
            data: {
              term: request.term,
            },
            success (data) {
              response(data.results)
            },
          })
        },
        minLength: this.options.suggestMinLength,
        open: () => {
          this.open = true
        },
        close: () => {
          this.open = false
        },
        select: (e, ui) => {
          this.options.callback?.(ui.item)
          this.$('.autocomplete-input').val('')
          return false
        },
        focus: (e, ui) => {
          this.focusedItem = ui.item
          return false
        },
        change: (e, ui) => {
          if (ui.item) {
            this.options.callback?.({
              field: 'text',
              value: this.$(e.target).val(),
            })
          }
        },
      })
      .on('keydown', (e) => {
        const keyCode = e.keyCode || e.which
        const value = this.$(e.target).val()

        if (keyCode === 13) {
          if (!(this.open && this.focusedItem)) {
            this.options.callback?.({
              field: 'text',
              value: value,
            })
          }
          this.$('.autocomplete-input').blur()
        }
      })
      .on('keyup', (e) => {
        const keyCode = e.keyCode || e.which
        const value = this.$(e.target).val()

        if (keyCode !== 13) {
          this.options.textCallback?.({
            field: 'text',
            value: value,
          })
        }
      })
    return this
  }

  updatePlaceholder (val) {
    this.$('.autocomplete-container input').attr('placeholder', val)
  }

  cleanup () {
    super.cleanup()
    this.$('.autocomplete-input').categorysearch('destroy')
    this.$('.autocomplete-input').off()
  }

  //
  // event handlers
  //
  onClearSearch (e) {
    this.$('.autocomplete-input').categorysearch('close')
    this.$('.autocomplete-container input').val('')
    this.options.callback?.({ field: 'text', value: '' })
    this.$('.autocomplete-container input').focus()

    return false
  }

  onSearch (e) {
    this.$('.autocomplete-input').categorysearch('close')
    this.options.callback?.({
      field: 'text',
      value: this.$('.autocomplete-input').val(),
    })
  }

  onSearchClicked (e) {
    this.$('.autocomplete-input').select()
  }
}

$.widget('custom.categorysearch', $.ui.autocomplete, {
  _create () {
    this._super()
    this.widget().menu('option', 'items', '> :not(.ui-autocomplete-category)')
  },

  _renderMenu (ul, items) {
    const that = this
    let currentCategory = ''
    ul.addClass('list-group-xs')
    ul.css('display', 'block !important')

    $.each(items, function (index, item) {
      if (item.category !== currentCategory) {
        if (item.category) {
          ul.append(
            `<li class='ui-autocomplete-category'>${item.category}</li>`
          )
        }
        currentCategory = item.category
      }

      const li = that._renderItemData(ul, item)
      if (item.category) {
        li.attr('aria-label', item.category + ' : ' + item.label)
      }
    })
  },

  _renderItem (ul, item) {
    let parentName
    const li = $(
      '<li class="list-group-item list-group-item-search list-group-item-action"></li>'
    )
    const iconMap = {
      subject: '',
      author: 'fa-user',
      publisher: 'fa-user',
      contest_sponsor: 'fa-certificate',
      registration: 'fa-user-graduate',
      assignment: 'fa-book-open',
      problem_set: 'fa-book-open',
      section: 'fa-chalkboard-teacher',
      order: 'fa-receipt',
      environment_template: 'fa-square',
      product: 'fa-book',
    }

    let icon = iconMap[item.field] || 'fa-flask'
    icon = `<span class='fa fa-fw blue ${icon} mr-2' aria-hidden='true'></span>`
    if (item.parent_name) {
      parentName = `${item.parent_name}`
    }
    const details = _([parentName, item.type, item.user_name])
      .compact()
      .join(' - ')

    const div = `<div class='container ml-0'> \
<div class='row align-items-center'> \
<div class='col-6 d-flex align-items-center font-reduced'> \
${icon} \
<div class='link'>${item.label}</div> \
</div> \
<div class='col-6 font-reduced'> \
${details} \
</div> \
</div> \
</div>`

    return li.data('ui-autocomplete-item', item).append(div).appendTo(ul)
  },
})
