/* global $ */
import Subscription from 'models/subscription'
import BaseView from 'views/base'

export default class TrialSubscription extends BaseView {
  el () {
    return '#trial-modal'
  }

  events () {
    return {
      'click #acknowledge-trial': 'onAcknowledgeTrial',
      'click #manage-subscription': 'onManageSubscription',
    }
  }

  initialize (options) {
    super.initialize(options)
    this.model = new Subscription(
      { id: this.options.subscriptionId },
      { parse: true }
    )
  }

  acknowledgeTrial (success) {
    this.model.save({ trial_acknowledged: true }, { success })
    $('#trial-modal').modal('hide')
  }

  show () {
    // force user to click a button to acknowledge
    $('#trial-modal').modal({
      backdrop: 'static',
      keyboard: false,
    })
  }

  //
  // event handlers
  //
  onAcknowledgeTrial () {
    this.acknowledgeTrial()
  }

  onManageSubscription () {
    this.acknowledgeTrial(() => (window.location = '/users/billing'))
  }
}
