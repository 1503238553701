import Backbone from 'backbone'
require('backbone')
require('backbone-nested')
require('backbone.queryparams')
require('backbone.modelbinder')
require('backbone.collectionbinder')
require('bootstrap')

// Override Backbone.sync to use the PUT HTTP method for PATCH requests
// when doing Model#save({...}, { patch: true });
const originalSync = Backbone.sync
Backbone.sync = (method, model, options) => {
  if (method == 'patch') {
    options.type = 'PUT'
  }
  originalSync(method, model, options)
}

export default Backbone
