import _templates_progress_bar_ from 'templates/progress_bar'
/* globals JST */
import BaseView from 'views/base'

export default class ProgressBar extends BaseView {
  initialize (options) {
    super.initialize(options)
    if (this.model) {
      this.attach(this.model)
    }
  }

  render () {
    this.$el.html(_templates_progress_bar_)
    this.$el.addClass('progress-slider')
    this.$el.attr('role', 'progressbar')
    this.$el.attr('aria-valuetext', 'Loading...')
    this.hide()

    return this
  }

  show () {
    this.$el.addClass('show')
    this.$el.attr('aria-hidden', 'false')
  }

  hide () {
    this.$el.removeClass('show')
    this.$el.attr('aria-hidden', 'true')
  }

  attach (model) {
    this.listenTo(model, 'sync command destroy error', this.onSync)
    this.listenTo(model, 'request', this.onRequest)
  }

  detach (model) {
    this.stopListening(model)
  }

  attachOnce (model) {
    if (model !== this.model) {
      this.listenToOnce(model, 'sync command destroy error', this.onSync)
      this.listenToOnce(model, 'request', this.onRequest)
    }
  }

  //
  // event handlers
  //
  onSync (model, resp, options) {
    if (!options.hideProgress) {
      this.hide()
    }
  }

  onRequest (model, resp, options) {
    if (!options.hideProgress) {
      this.show()
    }
  }
}
