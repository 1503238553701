/* global _, $, _E, I18n */

// patterned after Rails 'blank?'. An attribute is blank if it's false, empty, a blank string, or undefined.
export const isBlank = (value) =>
  value == null ||
  (typeof value === 'string' && value === '') ||
  (value instanceof Array && value.length === 0) ||
  (typeof value === 'boolean' && !value) ||
  (typeof value === 'object' && !(value instanceof Date) && _.size(value) === 0)

export const pluralize = (stem, num) =>
  stem + (num !== -1 && num !== 1 ? 's' : '')

export const parseBoolean = (value) => value === 'true' || value === '1'

export const numberToCurrency = (number) =>
  I18n.currencySymbol + number?.toFixed(2)

export const yesNo = function (bool) {
  if (bool) {
    return 'yes'
  } else {
    return 'no'
  }
}

// From https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
export const hashCode = function (string) {
  let hash = 0
  if (string.length === 0) {
    return hash
  }

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  } // Convert to 32bit integer

  return String(hash)
}

export const uniqueId = function (prefix) {
  _E.ids = _E.ids || {}
  if (!_E.ids[prefix]) {
    _E.ids[prefix] = 0
  }
  _E.ids[prefix] += 1
  return prefix + '-' + _E.ids[prefix]
}

export const getScriptOnce = function (url) {
  if (getScriptOnce.loaded.indexOf(url) === -1) {
    getScriptOnce.loaded.push(url)
    return $.getScript(url)
  } else {
    return false
  }
}
getScriptOnce.loaded = []

export const parallelLoad = function (libs, callback = $.noop) {
  let finished = 0
  const errs = []
  if (libs == null || libs.length === 0) {
    return callback()
  }
  const done = function () {
    finished++
    if (finished === libs.length) {
      return callback(errs)
    }
  }
  const failed = function () {}
  // TODO: handle failure

  _(libs).each(function (lib) {
    const loading = getScriptOnce(lib)
    if (loading) {
      loading.done(done).fail(failed)
    } else {
      done()
    }
  })
}

export const inlineScript = function (el, text) {
  const newScript = document.createElement('script')
  newScript.setAttribute('nonce', _E.nonce)
  newScript.appendChild(document.createTextNode(text))
  el.appendChild(newScript)
}

export const functionValue = function (fn, arg, bindToThis) {
  if (_.isFunction(fn)) {
    if (bindToThis) {
      fn = fn.bind(bindToThis)
    }
    return fn(arg)
  } else {
    return fn
  }
}

export const signSymbol = (num) => {
  return Math.sign(num) == 1 ? '+' : '-'
}
